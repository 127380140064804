@media only screen and (max-width: 540px) {
  .login-page .login-left-col {
    display: none;
  }
  .login-page .login-right-col {
    padding-top: 0px;
  }
  .login-page .login-right-col .lottie-img {
    text-align: center;
  }
  .login-page .login-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-wrapper {
    grid-template-columns: 1fr;
  }

  /* registration */
  .register-page .register-left-col {
    display: none;
  }
  .register-page .register-right-col {
    padding-top: 0px;
  }
  .register-page .register-right-col .lottie-img {
    text-align: center;
  }
  .register-page .register-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-wrapper {
    grid-template-columns: 1fr;
  }
  .register-page .form-grid {
    grid-template-columns: 1fr;
  }

  .sidebar-links .css-5uknkm-MuiTypography-root {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
  .login-page .login-left-col {
    display: none;
  }
  .login-page .login-right-col .lottie-img {
    text-align: center;
  }
  .login-page .login-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-wrapper {
    grid-template-columns: 1fr;
  }
  /* register */

  .register-page .register-left-col {
    display: none;
  }
  .register-page .register-right-col .lottie-img {
    text-align: center;
  }
  .register-page .register-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-wrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  /* .login-page .login-left-col {
    display: none;
  } */
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
}

@media print {
  .invoice_image {
    display: inline-block !important;
    padding-top: 15px;
  }
  .css-1thxjj-MuiSvgIcon-root {
    height: 0 !important;
    overflow: hidden !important;
    display: none;
  }
  /* @page { size: landscape; } */
  html,
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    height: 100vh; /* Use 100% here to support printing more than a single page*/
  }
  .invoice_print_form {
    /* position: absolute; */
    display: flex;
    justify-content: space-between;
    bottom: 20px;
    height: 100%;
  }
  .invoice_footer_form {
    position: absolute;
    display: flex !important;
    justify-content: space-between;
    bottom: 10px;
    width: 100%;
    padding: 25px;
  }
  .hide_from_invoice {
    display: none;
  }
}

.invoice_image {
  display: none;
}
.invoice-card-header {
  align-items: center;
}
.invoice_print_form {
  position: relative;
}
.invoice_footer_form {
  display: none;
}
.invoice_icon_section {
  padding: 10px;
}
@media (min-width: 960px) {
  .css-1t7poco-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

@media (min-width: 1280px) {
  .css-1t7poco-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
  justify-content: center;
}
